import {Grid, TextField} from '@material-ui/core';
import {useStyles} from '../css/Styles';
import ErrorInfoGrid from '../templates/ErrorInfoGrid';
import {useTranslation} from "react-i18next";


export default function CustomTextfield({
                                            title,
                                            options,
                                            value,
                                            inputValue,
                                            editState,
                                            changeHandler,
                                            inputHandler,
                                            maxWidth = false,
                                            name,
                                            id,
                                            required,
                                            selectOptionSufix,
                                            freeSolo = false,
                                            errors
                                        }) {

    const classes = useStyles();
    const {t} = useTranslation();


    return (
        <Grid item xs style={{maxWidth: maxWidth}} className={classes.daneOsoboweGridField}>

            <TextField
                className={(errors && errors.find(errors => errors.id === name)) ? classes.customTextFieldError : classes.customTextField}
                id={`${id}${selectOptionSufix}`}
                classes={{
                    input: classes.autoComplete,
                }}
                name={name}
                disableClearable={(required) ? true : false}
                freeSolo={freeSolo}
                options={options}
                value={value}
                onChange={changeHandler}
                inputValue={inputValue}
                onInputChange={inputHandler}
                onBlur={e => {
                    e.preventDefault();
                }}
                disabled={editState}
                style={{width: "100%"}}
                label={t(title)}
                disabled={editState}
                data-testid={name}
                variant="outlined"
                required={required}
            />
            <ErrorInfoGrid errors={errors} fieldName={name}></ErrorInfoGrid>
        </Grid>
    )
}